import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
//import Video from "components/Video/Video.jsx";
import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/cases/laura/laura_01.jpg";
import image2 from "assets/img/cases/laura/laura_02.jpg";
import image3 from "assets/img/cases/laura/laura_03.jpg";
import image4 from "assets/img/cases/laura/laura_04.jpg";
import image5 from "assets/img/cases/laura/laura_05.jpg";
import image6 from "assets/img/cases/laura/laura_06.jpg";
import image7 from "assets/img/cases/laura/laura_07.jpg";

import NEOT from "assets/img/logos/neot-480x480.png";
import Telia from "assets/img/logos/telia-480x480.png";

class LauraSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Improving the Safety of Fuel Loadings</h2>
              <h4>AI is helping humans to avoid rare but expensive mistakes in critical tasks</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Photos",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image5} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image6} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                {/* 
                                <div>
                                  <img src={image7} alt="Slide" className="slick-image" />
                                </div>
                                */}
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          Any mistake or wrong action performed by a human is called a human error.
                          A particularly difficult category of human errors are <i>slips</i>: a slip
                          is a frequently performed action done wrong - for example, filling up petrol
                          into a car that has a diesel engine. Slips often turn out to be more common
                          than we think and they lead to significant financial losses. In the UK alone,
                          300 000 vehicles are filled with the wrong fuel every year; costing £500
                          in repairs in average.
                        </p>
                        <p>
                          In 2019, Telia approached Finwe and asked if we could develop a real-time
                          safety solution based on video analytics for their customer NEOT. The goal
                          was to detect incorrect loading arm connections in the local oil terminal.
                          Tanker trucks come there to pick up loads that they deliver to gas stations.
                          Obviously, making a human error is significantly more expensive when loading
                          a tanker truck than filling up the family car. However, if wrong connections
                          could be detected using video analytics, the truck driver could be alerted
                          right on the spot and costly errors could be avoided.
                        </p>
                        <p>
                          To be honest, in the beginning everyone was feeling skeptical - including us.
                          Nevertheless, we familiarized ourselves with the loading process and
                          eventually did found a potential solution. Since experimenting in a real oil
                          terminal is restricted for safety reasons, we purchased a toy truck and built a
                          scale model using custom designed and 3D-printed parts. Using the model,
                          the concept could be validated and demonstrated to the customer. In the next
                          phase, the setup was assembled in full size and set up into the local oil
                          terminal.
                        </p>
                        <p>
                          Our approach was surprisingly simple: each vehicle is equipped with a 10 eur
                          marker sticker. Similar markers are added also to the terminal's loading arms.
                          A single video feed to video analytics algorithms allows observing connections
                          and comparing loading order to detected loading arm connections. If there is
                          a mismatch, the driver is alerted. This AI assistant does not get tired,
                          distracted by personal life issues, or let feelings interfere with work
                          performance. In other words, it is a great partner for a human worker and
                          shines exactly in those situations where humans - rarely, but still - make slips.
                          Futhermore, the AI does not have to be perfect to be useful: it is highly
                          unlikely that both the truck driver and the AI make a mistake at the same time.
                          The developed system is now in production use.
                        </p>
                        <p>
                          The scale model turned out to be useful also as a demo and a training kit.
                          We've made several copies of it and you may be able to try it out in
                          trade shows.
                        </p>
                        <p>
                          <b>
                            To learn how video analytics can be used in your company, contact us and book an
                            e-meeting. Our offering includes on-site consultation for analysing potential
                            applications in co-operation with the customer.
                          </b>
                        </p>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Customer:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={NEOT}
                              alt="NEOT"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Partner:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Telia}
                              alt="Telia"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                },
                {
                  tabName: "Links",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <p className={classes.textCenter}>
                        <a href="https://www.telia.fi/yrityksille/artikkelit/artikkeli/Tekoaly-virheellisten-polttoainelastausten-estajana">2019-10-25 TEKOÄLY VIRHEELLISTEN POLTTOAINELASTAUSTEN ESTÄJÄNÄ (by Telia, in Finnish)</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.neot.fi/en/ai-improves-the-safety-of-fuel-loadings/">2019-06-12 AI IMPROVES THE SAFETY OF FUEL LOADINGS (by NEOT)</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.ficom.fi/ajankohtaista/j%C3%A4senist%C3%B6n-tiedotteet/telia-uusi-teknologia-turvaa-kuljettajan-selustaa">2019-10-22 Uusi teknologia turvaa kuljettajan selustaa polttoainelastauksissa – ”Näissä tilanteissa tekoäly on parhaimmillaan” (in Finnish)</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.telecompaper.com/news/telia-finland-supports-ai-and-video-analytics-trial-for-fuel-filling-at-oulu-terminal--1313393">2019-10-24 Telia Finland supports AI and video analytics trial for fuel filling at Oulu terminal</a>
                      </p>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(LauraSection);
